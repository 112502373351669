import React from 'react'
import './products.scss'

function Products() {
  return (
<div>
  <h1>Products</h1>
  <ul>
    <li>Cabling & Physical Infrastructure</li>
    <li>Network Switches</li>
    <li>Network Audio-Visual Technology</li>
    <li>Building Management & Automation System</li>
    <li>Guest Room Management & Automation System</li>
    <li>Home Automation System</li>
    <li>Low voltage electrical and lighting solutions</li>
    <li>IPTV</li>
    <li>HSIA</li>
  </ul>
</div>
  )
}

export default Products