import React from 'react'
import './services.scss'

function Services() {
  return (
<div>
  <h1>Services</h1>
  <ul>
    <li>System Integrator</li>
    <li>Integration Management Services</li>
    <li>Consultancy Service Framework</li>
  </ul>
</div>
  )
}

export default Services